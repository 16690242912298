import { Box, IconButton, Tooltip } from "@mui/material";
import CollapsibleBody from "../../../collapsible";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { toast } from "react-toastify";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import QRCode from "qrcode.react";
import React from "react";

export default function LinksAndQrCodes(props) {
  const downloadQR = () => {
    const canvas = document.getElementById(props.code);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${props.code}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <CollapsibleBody
      expanded={props.openId === "links-qr-codes"}
      id="links-qr-codes"
      title={"Links & QR Codes"}
      icon={<QrCode2Icon />}
      handleClick={props.setOpenId}
      mainSx={{}}
    >
      <Box>
        <Box component="p" ml={3} mr={3} fontSize="18px">
          <br />
          <Box component="p" ml={3} mr={3} fontSize="15px">
            By <strong>Link</strong>: <br />
            Email link to send people directly to this Certificate of Authenticity.
            <br />
            <br />
            <em>Link:</em> artis.app/work/{props.code}
            <Tooltip title="Copy Link to Proof of Registration">
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(`artis.app/work/${props.code}`);
                  toast.success("Link to the Proof copied to clipboard!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: true,
                  });
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <br />

          <Box component="p" ml={3} mr={3} fontSize="15px">
            By <strong>QRCode</strong>: <br />
            QR Code below is encoded wtih a link to this Certificate of Authenticity. Attach the QR code to your work, or authorized copy of your work, and when people
            scan the QR code with a mobile phone, this Proof of Authenticity will load.
        
            <br />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <iframe
                title="QR code explainer"
                src="https://player.vimeo.com/video/755622176?h=1b0c5ce2f4"
                width="300"
                height="300"
                style={{
                  boxShadow: "1px 2px 5px #AAAAAA",
                  width: "100%",
                  height: "100%",
                  maxWidth: "300px",
                  maxHeight: "300px",
                  aspectRatio: "1 / 1",
                }}
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <br />
            <Box component="p" ml={3} mr={3}>
              <div align="center">
                {/* eslint-disable-next-line  */}
                <a
                  onClick={downloadQR}
                  style={{
                    color: "black",
                    cursor: "pointer",
                    fontSize: "1rem",
                  }}
                >
                  <QRCode
                    id={props.code}
                    value={`https://artis.app/work/${props.code}`}
                    size={100}
                  />
                  <br />
                  Click on QR code to download.
                </a>
              </div>
            </Box>
          </Box>
          <br />

          <Box component="p" ml={3} mr={3} fontSize="15px">
            By <strong>NFC </strong>:
            <br />
            If you encode an NFC tag with a link to this Certificate of Authenticity, and embed the NFC tag in your work, then when people bring their mobile phones close to your work, this Certificate of Authenticy will appear. See video below for instructions.
            <br />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <iframe
                title="explainer"
                src="https://player.vimeo.com/video/754282297?h=dc4c3f484f"
                width="300"
                height="300"
                style={{
                  boxShadow: "1px 2px 5px #AAAAAA",
                  width: "100%",
                  height: "100%",
                  maxWidth: "300px",
                  maxHeight: "300px",
                  aspectRatio: "1 / 1",
                }}
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <br />
            Encode the link below into an NFC tag and attach tag to your
            work.
            <br />
            <em>NFC Link:</em> artis.app/work/{props.code}
            <Tooltip title="Copy NFC Link">
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(
                    `artis.app/works/${props.code}`
                  );
                  toast.success("NFC Link copied to clipboard!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: true,
                  });
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </CollapsibleBody>
  );
}
